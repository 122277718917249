/* latin-ext */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/ubuntu_latinext_standard.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/ubuntu_latin_standard.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/ubuntu_latinext_bold.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/ubuntu_latin_bold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {  
  margin: 0;
}

p {
  margin: 0;
}

html, body, button, input, div, span, a {
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 2em;
  font-size: calc(10px + 0.8vmin);
}

@media (min-width: 1000px) {
  body {font-size: calc(10px + 0.5vmin);}
}

ul {
  list-style-type: none;
}

label {
  padding: 1%;
}

.title-text {
  font-weight: bold;
}

a, 
a:active, 
a:visited{
  color:#666666;
  font-weight: bold;
  text-decoration: none;
}

button {
    background-color: #37656d;
    color: #FFFFFF;
    border: none;
    outline: none;
    border-radius: 3px;
    padding: 0.8rem 0.8rem;
}

button:disabled {
  background-color: #76A2A7;
  color: #EEEEEE;
}

button:not(:disabled):hover {
  background-color: #294C51;
  cursor: pointer;
}

.linkButton {
  color: #666666;
  font-weight: bold;
  background-color: #FFFFFF;
  font-family: inherit;
}

#root {
  box-sizing: border-box;
}

#animation-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
}

#animation-container.show {
  height: 100%;
  padding: 1em;
}

.balloon {
  height: 125px;
  width: 105px;
  border-radius: 75% 75% 70% 70%;
  position: relative;
}

.balloon:before {
  content: "";
  height: 75px;
  width: 1px;
  padding: 1px;
  background-color: #FDFD96;
  display: block;
  position: absolute;
  top: 125px;
  left: 0;
  right: 0;
  margin: auto;
}

.balloon:after {
    content: "▲";
    text-align: center;
    display: block;
    position: absolute;
    color: inherit;
    top: 120px;
    left: 0;
    right: 0;
    margin: auto;
}

@keyframes float {
  from {transform: translateY(100vh);
  opacity: 1;}
  to {transform: translateY(-300vh);
  opacity: 0;}
}