.games {
  margin: 1% 0;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.games > div {
  margin: 2% 0;
}

.games ul {
  margin: 0;
  padding: 0;
}