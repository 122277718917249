.contextMenu{
  text-align: left;
  padding: 4px;
  background-color: #FFFFFF;
  box-shadow: 4px 4px 2px #888888;
  border: 2px solid #000000;
  z-index: 9999;
  min-width: 230px;
}

.context{
  display: block;
  width: 8px;
  height: 40px;
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  position: absolute;
  z-index: 999;
  cursor: pointer;
}

.radio{
  cursor: pointer;
}

.borders {
  padding: 8px;
}

.left, .right {
  height: 40px;
  width: 10px;
}

.top, .bottom {
  height: 10px;
  width: 40px;
}

.top {
  transform: translate(0, -37px);
}

.bottom {
  transform: translate(-49px, 8px);
}

.icon{
    stroke: #97938f;
    stroke-dasharray: 5;
}

.icon:hover {
  stroke: #4d4c4b;
  stroke-dasharray: 2;
  cursor: pointer;
}

.active {
  stroke-dasharray: 0;
}