.App {
  text-align: center;
  position: relative;
}

.dashboard {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
}
