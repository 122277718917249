.checkMe svg {
  fill: #FFFFFF;
}

.sticky {
  background-color: #37656d;
  color: #FFFFFF;
  position: fixed;
  bottom: 12px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
  padding: 7px 22px;
}

@media (max-width: 600px) {
  .sticky {
    width: 100%; 
    position: static;
  }
}

.sticky:hover {
  background-color: #294C51 !important;
}

.sticky svg {
  padding-left: 30px;
}