.editSolWord {
  background-color:#b5cacd;
  padding: 2%;
  border: 1px solid #76A2A7;
  border-radius: 5px;
  text-align: left;
  margin: 2%;
}

.editSolWord .title {
  color: #76A2A7;
}

.inputSolWord {
  min-width: calc(10 * 1vmin);
}