.stats {
  background-color: #b5cacd;
  padding: 2%;
  border: 1px solid #76A2A7;
  border-radius: 5px;
  text-align: left;
  margin: 2%;
  max-width: 575px;
}

.desc {
  width: 25px;
  margin-right: 4px;
}

.descLong {
  width: 74px;
  margin-right: 4px;
}

.stats .title {
  color: #76A2A7;
}

.statsLists {
  display: flex;
  flex-wrap: wrap;
}

.statsLists li {
  display: flex;
  align-items: center;
  line-height: 100%;
  padding: 2%;
}