.qBox{
  display: block;
  position: fixed;
  color: #ffffff;
  background-color: #2F575D;
  width: 100%;
  padding: 30px 50px;
  margin: 0;
  box-sizing: border-box;
  z-index: 9999;
  top: 0;
  box-shadow: -5px 4px 10px 0px #333333;
  text-align: left;
  transition: transform 0.4s;
}

.qBoxRow {
  display: flex;
  align-items: center;
}

.qBoxRow input {
  width: 80%;
  margin-left: auto;
}

.scrollDown {
  transform: translate3d(0, -100%, 0);
}

.scrollUp {
  transform: none;
}

.closeIcon {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 4px;
  top: 4px;
}

.closeIcon svg {
  fill: #FFFFFF;
}