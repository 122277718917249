.statsBar {
  width: 100%;
  background-color: #d8d8d8;
  border-radius: 12px;
  height: 18px;
  color: #ffffff;
  position: relative;
}
.animate {
  background-color: #37656d;
  line-height: 100%;
  display: block;
  border-radius: 12px;
  position: absolute;
}

.amount {
  position: absolute;
  line-height: 100%;
  width: 100%;
  text-align: center;
}