.cell {
  outline: none;
  /* border: 1px solid #000000; */
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  box-shadow: 1px 0 0 0 #000000, 
  0 1px 0 0 #000000, 
  2px 1px 0 0 #000000, 
  1px 0 0 0 #000000 inset, 
  0 1px 0 0 #000000 inset;
}

@media (max-width: 600px) {
  .cell input[type="text"]{
    user-select: none;
  }
}

.cell input[type="text"]{
  width: 98%;
  height: 98%;
  font-size: 1.1em;
  text-align: center;
  position: relative;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  top: 1px;
  left: 1px;
  transition: none;
  -webkit-transition: none;
}

.borderLeft{
  border-left: 1px solid #000000;
}

.borderRight{
  border-right: 1px solid #000000;
}

.borderBottom{
  border-bottom: 1px solid #000000;
}

.borderTop{
  border-top: 1px solid #000000;
}

.highlighted, .highlighted input[type="text"] {
  background-color: #E4F0F1;
}

.active, .active input[type="text"] {
  background-color:#AED1D6;
}

.disabled {
  padding: 0;
  margin: 0;
  position: relative;
  box-sizing: border-box;
  box-shadow: none;
}

.disabled input {
  background-color: #FDFDFD;
}

.solWord, .solWord input[type="text"] {
  background-color: #99AEAD;
}

.solWord.highlighted, .solWord.highlighted input[type="text"] {
  background-color: #6F959B;
}

.borderedH {
  box-shadow: 1px 1px 0 0 #000000 inset, 1px 1px 2px 0 #333333;
  z-index: 3;
}

.borderedV {
  box-shadow: 1px 1px 0 0 #000000 inset, 1px 1px 2px 0 #333333;
  z-index: 3;
}

.qNr{
  position: absolute;
  z-index: 8888;
  padding: 2px 0 0 4px;
  font-size: 0.6em;
  line-height: 100%;
}