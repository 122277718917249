.gameBoard {
  width: max-content;
  margin: 2.5% auto auto auto;
}

.gameBoard.leftAlign {
  margin: 0;
}

.row{
  position: relative;
  border-collapse: collapse;
  display: flex;
}