.editSidebar {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  background-color: #ebf6f7;
  z-index: 9999;
  box-shadow: 0 0px 7px 1px #333333;
  border-left: 1px solid #333333;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
  transform: translateX(100%);
  transition: transform 250ms ease-in-out;
}

.editSidebar.open {
  transform: translateX(0);
}

.toggleSidebar {
  position: absolute;
  left: 0;
  top: 30%;
  transform: translateX(-100%);
}