.cell {
  box-shadow: 3px 4px 5px 0px #2F575D;
  background-color: #99AEAD;
  color: #FFFFFF;
  max-height: 75px;
  max-width: 75px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  border-left: 1px solid #FFFFFF;
  cursor: pointer;
  padding: 2px;
  box-sizing: border-box;
}

.cellEditable {
  border-right: none;
  border-top: none;
  border-bottom: none;
  outline: none;
  text-align: center;
}

.cell:hover, .cell.active {
  background-color: #6F959B;
}

.solutionWord {
  padding: 4% 2%;
}

.heading {
  font-weight: bold;
}

.question {
  padding-bottom: 2%;
  line-height: 1.2em;
}

.answer {
  display: flex;
  align-items: center;
  justify-content: center;
}