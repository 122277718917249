.gameListItem {
  background: white;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  counter-increment: gradient-counter;
  margin: 1rem 0;
  position: relative;
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
}

.gameListItem span {
  border-radius: 0 0 0 0.5rem;
}

.gameListItem.published span {
  padding: 0.2rem 0.4rem;
  border-radius: 0 0 0 0.5rem;
}

.gameListItem.editable span {
  padding: 0.4rem;
  border-radius: 0 0 0 0.5rem;
}

.gameListItem.published a {
  display: flex;
  justify-content: space-between;
}

.gameListItem a:hover {
  color:#CCCCCC;
}

.gameNr {
  font-weight: bold;
  background-color: #CCCCCC;
  color: #FFFFFF;
}