.qList{
    padding: 10px 0;
    list-style-type: none;
    margin: 0;
}

.qList li{
    display: flex;
}

.qListTitle{
    font-weight: bold;
}

.qNumber{
    font-weight: bold;
    padding-right: 10px;
    text-align: right;
    min-width: 5%;
}

.allQuestions{
    text-align: justify;
    margin: 0 auto;
    display: table;
}

.allQuestions > div {
    padding: 2vh;
}