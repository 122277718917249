.tooltip {
  position: absolute;
  top: 100px;
  left: 50%;
  min-width: 80%;
  transform: translateX(-50%);
  background-color: #DEE9E8;
  z-index: 99999;
  box-shadow: 0 4px 10px 1px #333333;
}

.tooltip .title {
  font-weight: bold;
}

.tooltip p {
  margin-bottom: 14px;
}

.icon {
  height: 12px;
  line-height: 100%;
}

.icon svg {
  width: 30px;
  height: 30px;
  position: relative;
  top: 9px;
  left: 4px;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.helpText {
  padding: 2%;
  text-align: left;
}

.helpText p {
  line-height: 1.5em;
}