.msgBox {
  position: absolute;
  line-height: 110%;
  padding: 1rem;
  top: 10%;
  left: 50%;
  min-width: 60%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  box-shadow: 4px 4px 2px #000000;
  z-index: 9999;
  /*animation: easeOut 0.6s linear 3s forwards;*/
}

.msgBox.success {
  background-color: #abcfae;
  box-shadow: 4px 4px 2px #225f26;
}

.msgBox.error {
  background-color: #fde4e4;
  box-shadow: 4px 4px 2px  #cc2400;
}

@keyframes easeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes easeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}