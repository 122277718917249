.qBox{
  display: block;
  position: fixed;
  color: #ffffff;
  background-color: #2F575D;
  width: 100%;
  padding: 2%;
  margin: 0;
  box-sizing: border-box;
  z-index: 9999;
  top: 0;
  box-shadow: -5px 4px 10px 0px #333333;
  text-align: left;
  transition: transform 0.4s;
}

.qBoxRowSolve {
  display: flex;
}

.qBoxRowSolve.active {
  font-weight: bold;
}

.qBoxRowNr {
  margin-right: 10px;
  min-width: fit-content;
}

.scrollDown {
  transform: translate3d(0, -100%, 0);
}

.scrollUp {
  transform: none;
}
