.iconButton {
  background-color: transparent;
  padding: 5px;
  cursor: pointer;
  line-height: 100%;
  z-index: 888;
}

.iconButton svg {
  width: 35px;
  height: 35px;
}

.iconButton:hover, .iconButton:active {
  background-color: transparent !important;
}

.iconButton:disabled {
  background-color: transparent !important;
}
