@keyframes spinner {
    0% {
        transform: rotate(0deg);
        fill: #FFFFFF;
    }
    50% {
        transform: rotate(180deg);
        fill: #000000;
    }
    100% {
        transform: rotate(360deg);
        fill: #FFFFFF;
    }
}

.waiting-animation {
    animation: spinner infinite 6s linear;
}

.loader{
    margin: 2% auto;
    opacity: 1;
}

.icon {
    width: 15%;
    height: 15%;
    composes: waiting-animation;
}