.gameHead {
  display: flex;
  align-items: center;
}

.title {
  flex-grow: 1;
}

.iconNavigation {
  min-width: 90px;
  display: flex;
}

.iconNavigation.left {
  justify-content: flex-start;
}

.iconNavigation.right {
  justify-content: flex-end;
}